import About from "@/components/Shared/About/About";
import Profile from "@/components/Shared/Profile/Profile";
import { Subheading } from "@/components/Shared/Subheading/Subheading";
import TextBanner from "@/components/Shared/TextBanner/TextBanner";
import { Box, BoxProps, chakra, SimpleGrid, Stack } from "@chakra-ui/react";
import React from "react";

export interface AboutSectionProps extends BoxProps {}

export const AboutSection = (props: AboutSectionProps) => {
  const { ...restOfProps } = props;
  return (
    <Box {...restOfProps}>
      <Stack spacing={12}>
        <SimpleGrid columns={{ md: 2 }} gap={{ base: 10 }} h={{ md: "640px" }}>
          <About />
          <Profile />
        </SimpleGrid>
        <Subheading fontSize="2xl" fontStyle="italic" textAlign="center">
          “Narodenie nového človeka je tým{" "}
          <chakra.span color="primary">najkrajším momentom</chakra.span>, ktorý
          môžete v priebehu svojho života zažiť.”
        </Subheading>
        <TextBanner />
      </Stack>
    </Box>
  );
};
