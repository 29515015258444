import { Box, Flex, Heading, Image, Text } from "@chakra-ui/react";
import React from "react";
import feetImage from "../../../assets/feet.png";

const TextBanner = () => {
  return (
    <Box boxShadow="xl">
      <Flex direction={{ base: "column", md: "row" }} w="full">
        <Image
          src={feetImage}
          w={{ base: "100%", md: "20%" }}
          h={{ base: "360px", md: "auto" }}
          fit="cover"
        />
        <Box
          w={{ base: "full", md: "80%" }}
          bg="primary"
          color="white"
          px="30px"
          fontSize="lg"
        >
          <Heading size="lg" fontWeight="600" mt="30px" mb="20px">
            Prečo absolvovať tehotenský kurz?
          </Heading>
          <Text mb="15px">
            Narodenie zdravého dieťatka je snom každej mamičky. Tejto radostnej
            udalosti predchádza tehotenstvo a pôrod, ktoré môžu byť sprevádzané
            rozličnými komplikáciami, čo zo samotného pôrodu môže urobiť
            mimoriadne stresujúcu situáciu, až nepríjemný zážitok. Pre rodičov
            je nesmierne potrebné poznať a pripraviť sa na jednotlivé situácie,
            čo v konečnom dôsledku výrazne napomáha zdarnému priebehu gravidity,
            ako aj pôrodu.
          </Text>
          <Text mb="15px">
            Množstvo zmien, ku ktorým v priebehu tehotenstva dochádza pripravujú
            matku, ako aj jej partnera na príchod novorodenca. Stretnutie s
            tehotnou počas vyšetrenia v ambulancii časovo nedovoľuje venovať sa
            všetkým náležitostiam súvisiacim s tehotenstvom a pôrodom. Nakoľko
            je väčšina rodičiek nepripravená na tieto zmeny a nemá sa kde k
            odpovediam na rôzne otázky dostať, pripravili sme si pre Vás tieto
            kurzy, cieľom ktorých je pripraviť mamičku, v prípade záujmu aj
            partnera, na tehotenstvo a pôrod.
          </Text>
          <Text mb="15px">
            Absolvovanie kurzu Vám pomôže pripraviť sa na tento úžasný moment a
            urobiť z neho celoživotný zážitok s úspešným koncom, za čo Vám vaše
            ratolesti v budúcnosti poďakujú.
          </Text>
          <Heading
            size="lg"
            fontWeight="600"
            mb="30px"
            textAlign={{ base: "center", md: "left" }}
          >
            Máte otázku? Zavolajte nám: +421 948 404 993
          </Heading>
        </Box>
      </Flex>
    </Box>
  );
};

export default TextBanner;
