import { Box, BoxProps, HStack } from "@chakra-ui/react";
import React, { ReactNode } from "react";
import { AiOutlineInfoCircle } from "react-icons/ai";

export interface InfoNoteProps extends BoxProps {
  children: ReactNode | ReactNode[];
}

export const InfoNote = (props: InfoNoteProps) => {
  const { children, ...restOfProps } = props;
  return (
    <Box {...restOfProps}>
      <HStack>
        <Box color="primary" fontSize="xl" fontWeight={700}>
          <AiOutlineInfoCircle />
        </Box>
        <Box>{children}</Box>
      </HStack>
    </Box>
  );
};
