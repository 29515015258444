import hero from "@/assets/hero.png";
import { AboutSection } from "@/components/home/AboutSection/AboutSection";
import { ConsultingSection } from "@/components/home/ConsultingSection.tsx/ConsultingSection";
import { CourseSection } from "@/components/home/CourseSection.tsx/CourseSection";
import { Hero } from "@/components/home/Hero/Hero";
import { Container } from "@/components/Shared/Container/Container";
import { awakeHerokuBackend } from "@/lib/api/gqlApi";
import { routes } from "@/lib/routes";
import { Stack } from "@chakra-ui/layout";
import { chakra } from "@chakra-ui/system";
import React, { useEffect } from "react";
import Layout from "../components/Shared/Layout/Layout";
import { SEO } from "../components/Shared/SEO/SEO";

const IndexPage = (props: any) => {
  // awake heroku
  useEffect(() => {
    try {
      awakeHerokuBackend();
    } catch (e) {
      console.log(e);
    }
  }, []);

  return (
    <Layout>
      <SEO description="This is an index page" />
      <Hero
        imageUrl={hero}
        title={"POZNANIE A PRÍPRAVA"}
        description={
          <>
            Pre rodičov je potrebné poznať a pripraviť sa na jednotlivé
            situácie, čo v konečnom <br className="hidden xl:flex" />
            dôsledku{" "}
            <chakra.span color="primary">
              výrazne napomáha úspešnému priebehu gravidity
            </chakra.span>
            , ako aj pôrodu.
          </>
        }
        cta={{
          text: "Predpôrodné kurzy",
          href: routes.courses.to
        }}
      />
      <Container>
        <Stack spacing={{ base: 12, md: 20 }} py={{ base: 14, md: 20 }}>
          <AboutSection id={routes.about.to.slice(1)} />
          <CourseSection id={routes.courses.to.slice(1)} />
          <ConsultingSection id={routes.consulting.to.slice(1)} />
        </Stack>
      </Container>
    </Layout>
  );
};

export default IndexPage;
