import { Box, BoxProps, Flex, Image, Stack } from "@chakra-ui/react";
import React from "react";

export interface ReviewProps extends BoxProps {
  name: string;
  content: string;
  imageUrl: string;
}

export const Review = (props: ReviewProps) => {
  const { name, content, imageUrl, ...restOfProps } = props;
  return (
    <Box {...restOfProps} maxW="xl">
      <Flex
        gridGap={6}
        flexDir={{ base: "column", md: "row" }}
        alignItems={{ base: "center", md: "flex-start" }}
        textAlign={{ base: "center", md: "left" }}
      >
        <Image src={imageUrl} h={32} w={32} rounded="full" objectFit="cover" />
        <Stack>
          <Box fontSize="lg" fontWeight={700}>
            {name}
          </Box>
          <Box fontStyle="italic">{content}</Box>
        </Stack>
      </Flex>
    </Box>
  );
};
