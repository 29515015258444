import profilePhoto from "@/assets/profile.jpg";
import { Box } from "@chakra-ui/layout";
import { Flex, Icon, Image, List, ListItem, Text } from "@chakra-ui/react";
import React from "react";
import { AiOutlineFileDone } from "react-icons/ai";

const Profile = () => {
  return (
    <Flex flexDir="column" alignItems="flex-end">
      <Image
        src={profilePhoto}
        w={{ base: "full", lg: "82%" }}
        h={{ base: "480px", sm: "540px", md: "600px" }}
        boxShadow="2xl"
        objectPosition="50% 20%"
        alignSelf="flex-start"
        objectFit="cover"
      />
      <Box
        display="flex"
        flexDirection={"row"}
        alignItems="flex-start"
        px={{ base: 2, md: 4, lg: 8 }}
        py={{ base: 4, md: 6 }}
        // w={{ base: "full", md: 440 }}
        bg="primary"
        boxShadow="2xl"
        w={{ base: "full", md: "full", lg: "md" }}
        mt={{ base: 0, md: -24, lg: -28 }}
      >
        <Box>
          <Icon
            as={AiOutlineFileDone}
            w={16}
            h={16}
            color="white"
            mr={{ base: 2, md: 6 }}
          />
        </Box>
        <Box display="flex" flexDirection="column">
          <Text color="white" fontWeight="600" fontSize="xl" mb={2}>
            Vzdelanie (pôrodná asistencia)
          </Text>
          <List color="white">
            <ListItem display="flex" gridGap={1}>
              <Box fontSize="xl" mb={-0.5} mt={-0.5}>
                ✓
              </Box>
              Stredná zdravotnícka škola
            </ListItem>
            <ListItem display="flex" gridGap={1}>
              <Box fontSize="xl" mb={-0.5} mt={-0.5}>
                ✓
              </Box>
              Slovenská zdravotnícka univerzita
            </ListItem>
            <ListItem display="flex" gridGap={1}>
              <Box fontSize="xl" mb={-0.5} mt={-0.5}>
                ✓
              </Box>
              Vysoká škola sv. Ladislava
            </ListItem>
          </List>
        </Box>
      </Box>
    </Flex>
  );
};

export default Profile;
