import { PricingCard } from "@/components/Courses/PricingCard/PricingCard";
import { ContactLinks } from "@/components/Shared/ContactLinks/ContactLinks";
import { InfoNote } from "@/components/Shared/InfoNote/InfoNote";
import { SectionHeader } from "@/components/Shared/SectionHeader/SectionHeader";
import { SectionHero } from "@/components/Shared/SectionHero/SectionHero";
import {
  Box,
  BoxProps,
  chakra,
  Flex,
  GridItem,
  SimpleGrid,
  Stack,
  Text
} from "@chakra-ui/react";
import image from "../../../assets/consultingBanner.png";
import React from "react";

export interface ConsultingSectionProps extends BoxProps {}

export const ConsultingSection = (props: ConsultingSectionProps) => {
  const { ...restOfProps } = props;
  return (
    <Box {...restOfProps}>
      <SectionHero title={"LAKTAČNÉ PORADENSTVO"} imageUrl={image} />
      <SimpleGrid
        mt={{ base: 8, md: 14 }}
        columns={12}
        gap={{ base: 0, md: 4, xl: 20 }}
        rowGap={{ base: 4, md: 8 }}
      >
        <GridItem colSpan={{ base: 12, md: 8 }} h="full">
          <Flex flexDir="column" h="full">
            <SectionHeader
              title="AKO DOJČIŤ BÁBÄTKO SPRÁVNE"
              mb={{ base: 2, md: 3 }}
            />
            <Flex
              flexDir="column"
              justifyContent="space-between"
              h="full"
              fontSize="lg"
            >
              <Stack spacing={3}>
                <Text fontSize="2xl" fontWeight="600">
                  Dodržiavame všetky aktuálne hygienické opatrenia COVID-19
                </Text>

                <Text as="p">
                  Telo maminky sa pripravuje na dojčenie už aj počas
                  tehotenstva. Počas pôrodu sa vyplavujú hormóny, ktoré
                  spôsobujú tvorbu materského mliečka. Dojčenie je prirodzené a
                  pre bábätko najvýhodnejšie. Prináša mu dokonale vyváženú a
                  ľahko stráviteľnú stravu, doplnenú o imunitné prvky v ideálnej
                  teplote a v pohodlí maminej náruče.
                </Text>

                <Text as="p">
                  Väčšina maminiek sa pri starostlivosti o bábätko ocitá v novej
                  situácií a ani tie najlepšie rady z kníh a internetu nemusia
                  byť v tomto prípade užitočné. Každá žena je iná, každé bábätko
                  môže reagovať rôzne. Po pôrode je telo zoslabnuté, prežíva
                  záplavu hormónov, zmenu režimu a kolotoč kŕmenia, prebaľovania
                  a uspávania. Je prirodzené, že pre mamičku je to veľká záťaž,
                  ktorá môže mať vplyv na tvorbu mlieka. Mamička by si mala
                  nastaviť režim tak , aby seba aj bábätko nevystavovala stresu.
                </Text>
                <Text as="p">
                  Najmä v začiatkoch mamička potrebuje pomôcť s dojčením, s
                  režimom pre svoje bábätko, má množstvo otázok ohľadom stravy
                  na podporu laktácie, hľadá najlepšie pomôcky a spôsoby.
                </Text>
                <Text as="p">
                  V tomto období je podpora rodiny a kamarátok veľmi dôležitá,
                  ale rady nefungujú univerzálne pre všetkých. A čo sa robilo v
                  časoch nášho detstva sa už často dnes posunulo inam.
                </Text>
                <Text as="p">
                  Aby si celou cestou nemusela mamička prejsť sama, je tu
                  jednoduchá možnosť obrátiť na našu profesionálnu laktačnú
                  konzultantku so zdravotníckym vzdelaním a praxou, ktorá Vám
                  rada pomôže a poradí. Či už u nás v ambulancií alebo priamo u
                  vás doma.
                </Text>
              </Stack>

              <InfoNote my={{ base: 6, md: 4 }}>
                <Box fontWeight={700}>
                  V cene je{" "}
                  <chakra.span color="primary">
                    zahrnutá cesta poradkyne{" "}
                  </chakra.span>
                  , aj rôzne pomôcky k vykonávaniu poradenstva
                </Box>
              </InfoNote>

              <ContactLinks className="hidden md:flex" mt={4} />
            </Flex>
          </Flex>
        </GridItem>
        <GridItem colSpan={{ base: 12, md: 4 }}>
          <PricingCard
            title={
              <Box>
                Laktačné poradenstvo ako
                <br />
                dojčiť bábätko správne
              </Box>
            }
            price="20€ až 40€"
            description="Cena jedného stretnutia"
            contentItems={[
              "Príprava na dojčenie už počas tehotenstva",
              "Správne polohy pri dojčení",
              "Podpora tvorby materského mlieka",
              "Správne prisatie bábätka",
              "Pomoc s dojčením po pôrode",
              "Odsávanie materského mlieka",
              "Ako uskladňovať materské mlieko",
              "Zavádzanie pokrmov a dojčenie",
              "Odstavenie dieťaťa od materského mlieka"
            ]}
            cta={{
              text: "Objednať kurz",
              href: `/vytvorit-objednavku?courseId=${2}`
            }}
          />
        </GridItem>
      </SimpleGrid>
      <ContactLinks className="md:hidden" mt={12} />
    </Box>
  );
};
