import { Flex, Stack, Text } from "@chakra-ui/layout";
import { chakra } from "@chakra-ui/system";
import React from "react";
import { SectionHeader } from "../SectionHeader/SectionHeader";

const About = () => {
  return (
    <Flex direction="column" gridGap={6}>
      <SectionHeader
        title="O nás"
        maxW="md"
        subtitle={
          <>
            Záleží nám na tom,{" "}
            <chakra.span color="primary">
              aby ste sa u nás <br /> cítili príjemne{" "}
            </chakra.span>
            a odchádzali spokojné
          </>
        }
      />
      <Stack spacing={3} fontSize="lg">
        <Text fontSize="xl" fontWeight="600">
          Prečo si vybrať Prenat Baby?
        </Text>
        <Text>
          Kurzy prebiehajú v rodinnej atmosfére, kde sa nám môžete zdôveriť
          svojimi otázkami / ťažkosťami.
        </Text>
        <Text>
          Záleží nám na tom, aby ste sa u nás cítili príjemne. Pre to sú naše
          priestory zrekonštruované, príjemné a kľudné, aby ste sa u nás cítili
          uvoľnene.
        </Text>
        <Text>
          Vážime si dôveru každej našej klientky, pre to sa snažíme každej
          klientke vísť v ústrety.
        </Text>
      </Stack>
      <Stack spacing={3}>
        <Text fontSize="xl" fontWeight="600">
          Bc. Kristína Šišková
        </Text>
        <Text fontSize="lg">
          Som maminka vytúženej dcérky. Ako diplomovaná pôrodná asistentka vám
          môžem pomôcť prekonať strach z pôrodu, obavy, nedôveru, dodať odvahu,
          vieru v seba, svoje telo, vaše bábätko a pripraviť vás na pokojný a
          prirodzený pôrod, na ktorý budete s láskou spomínať. Pomôžem vám aj
          pri správnom dojčení, abz sa aj maminka aj bábätko cítili komfortne a
          sebaisto. Milé maminky, veľmi sa na vás teším.
        </Text>
      </Stack>
    </Flex>
  );
};

export default About;
