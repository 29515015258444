import { PricingCard } from "@/components/Courses/PricingCard/PricingCard";
import { ContactLinks } from "@/components/Shared/ContactLinks/ContactLinks";
import { Gallery } from "@/components/Shared/Gallery/Gallery";
import { InfoNote } from "@/components/Shared/InfoNote/InfoNote";
import { Review } from "@/components/Shared/Review/Review";
import { SectionHeader } from "@/components/Shared/SectionHeader/SectionHeader";
import { SectionHero } from "@/components/Shared/SectionHero/SectionHero";
import {
  Box,
  BoxProps,
  chakra,
  Flex,
  GridItem,
  SimpleGrid,
  Stack,
  Text
} from "@chakra-ui/react";
import image from "../../../assets/courseBanner.png";
import galleryImage1 from "../../../assets/gallery1.png";
import galleryImage2 from "../../../assets/gallery2.png";
import galleryImage3 from "../../../assets/gallery3.png";
import galleryImage4 from "../../../assets/gallery4.png";
import reviewImage from "../../../assets/monika-a-peter.png";
import React from "react";

export interface CourseSectionProps extends BoxProps {}

export const CourseSection = (props: CourseSectionProps) => {
  const { ...restOfProps } = props;
  return (
    <Box {...restOfProps}>
      <SectionHero title={"PREDPÔRODNÉ KURZY"} imageUrl={image} />
      <SimpleGrid
        mt={{ base: 8, md: 14 }}
        columns={12}
        gap={{ base: 0, md: 4, xl: 20 }}
        rowGap={{ base: 4, md: 8 }}
      >
        <GridItem colSpan={{ base: 12, md: 8 }} h="full">
          <Flex flexDir="column" h="full">
            <SectionHeader
              title="KURZ PREDPÔRODNEJ PRÍPRAVY"
              mb={{ base: 2, md: 3 }}
            />
            <Flex
              flexDir="column"
              justifyContent="space-between"
              h="full"
              fontSize="lg"
            >
              <Stack spacing={3}>
                <Text fontSize="2xl" fontWeight="600">
                  Dodržiavame všetky aktuálne hygienické opatrenia COVID-19
                </Text>
                <Text as="p">
                  Predpôrodná príprava s našou kvalifikovanou pôrodnou
                  asistentkou vás dôkladne pripraví nielen na pôrod, ale aj na
                  dôležité momenty o vaše bábätko. Dozviete sa tu všetky
                  dôležité informácie o vašom tehotenstve, aby ste si mohli toto
                  jedinečné obdobie života ženy naplno užiť. Naša pôrodná
                  asistentka vás prevedie množstvom tém.
                </Text>
                <Text as="p">
                  Kurz sa odporúča začať budúcim mamičkám od 23-25 týždňa
                  tehotenstva a budú ho navštevovať do 35-36 týždňa.
                </Text>
                <Text as="p">
                  Sme pripravení vyjsť v ústrety individuálnym potrebám. V
                  takomto prípade nás neváhajte kontaktovať.
                </Text>
              </Stack>

              <InfoNote my={{ base: 6, md: 4 }}>
                <Box fontWeight={600}>
                  Kurz pozostáva z{" "}
                  <chakra.span color="primary">10 konzultácií </chakra.span>,
                  raz týždenne. V slovenskom aj madarskom jazyku.
                </Box>
              </InfoNote>
              <Review
                py={6}
                imageUrl={reviewImage}
                name="Anita a András"
                content="Sme veľmi spokojný. Na kurze predpôrodnej prípravy v Prenat Baby sme sa naučili veľa dôležitých informácií a prakticky sme si vyskúšali všetko potrebné. Ďakujeme."
              />
              <ContactLinks className="hidden md:flex" />
            </Flex>
          </Flex>
        </GridItem>
        <GridItem colSpan={{ base: 12, md: 4 }}>
          <PricingCard
            title={
              <Box>
                Kurz predpôrodnej prípravy <br /> (od 23-36 týždňa tehotenstva)
              </Box>
            }
            price="160€"
            description="Cena kurzu (8 x 60min)"
            contentItems={[
              "Sprievodca tehotenstvom",
              "Príprava na bábätko",
              "Kedy odísť do pôrodnice",
              "Nácvik úľavových polôh pri kontrakciách",
              "Ako prebieha pôrod ",
              "Partner pri pôrode",
              "Čo je bonding",
              "Starostlivosť o novorodenca",
              "Prvá pomoc"
            ]}
            cta={{
              text: "Objednať kurz",
              href: `/vytvorit-objednavku?courseId=${1}`
            }}
          />
        </GridItem>
      </SimpleGrid>
      <ContactLinks className="md:hidden" mt={12} />
      <Gallery
        mt={{ base: 10, md: 16 }}
        images={[galleryImage1, galleryImage2, galleryImage3, galleryImage4]}
      />
    </Box>
  );
};
