import { Button } from "@/components/Shared/Button/Button";
import { Box, Flex, HStack, Stack } from "@chakra-ui/react";
import { Link } from "gatsby";
import React, { ReactNode } from "react";

export interface PricingCardProps {
  title: string | ReactNode | ReactNode[];
  price: string;
  description: string;
  contentItems: string[];
  cta: {
    text: string;
    href: string;
  };
}

export const PricingCard = (props: PricingCardProps) => {
  const { title, price, description, contentItems, cta, ...restOfProps } =
    props;
  return (
    <Box {...restOfProps} boxShadow="xl">
      <Box bg="primary" color="white" w="full" textAlign="center" p={4}>
        <Stack spacing={1}>
          <Box fontSize="2xl" fontWeight={600}>
            {title}
          </Box>
          <Stack spacing={0}>
            <Box fontSize="5xl" fontWeight="600" h={16}>
              {price}
            </Box>
            <Box>{description}</Box>
          </Stack>
        </Stack>
      </Box>
      <Box bg="bg" border="1px" borderColor="#e0e0e0" borderTop="none">
        <Stack p={4} py={6} spacing={1}>
          {contentItems.map((item) => (
            <HStack fontSize={{ base: "md", md: "md" }}>
              <Box fontSize="lg" fontWeight={600}>
                ✓
              </Box>
              <Box>{item}</Box>
            </HStack>
          ))}
        </Stack>
        <Flex pb={6} pt={1} justifyContent="center">
          <Link to={cta.href}>
            <Button size="lg">{cta.text}</Button>
          </Link>
        </Flex>
      </Box>
    </Box>
  );
};
