import { Box, BoxProps, GridItem, Image, SimpleGrid } from "@chakra-ui/react";
import React from "react";

export interface GalleryProps extends BoxProps {
  images: string[];
}

export const getDynamicColNumber = (
  itemsNumber: number,
  defaultColNumber: number,
  minColNumber = 1
) => {
  if (itemsNumber < defaultColNumber) {
    if (itemsNumber < minColNumber) {
      return minColNumber;
    } else {
      return itemsNumber;
    }
  } else {
    defaultColNumber;
  }
  return defaultColNumber;
};

export const Gallery = (props: GalleryProps) => {
  const { images, ...restOfProps } = props;
  const count = images.length;

  return (
    <Box {...restOfProps}>
      <SimpleGrid
        columns={{
          base: getDynamicColNumber(count, 2),
          md: getDynamicColNumber(count, 4, 2),
          lg: getDynamicColNumber(count, 6, 4)
        }}
        w="full"
        gap={{ base: 5, lg: 12 }}
      >
        {images.map((img) => {
          return (
            <GridItem position="relative" w="full" boxShadow="lg">
              <Image src={img} alt={img} objectFit="cover" />
            </GridItem>
          );
        })}
      </SimpleGrid>
    </Box>
  );
};
